<style src="./FormFirstAccess.scss" lang="scss" scoped></style>
<template>
  <section class="form-first-access">
    <div class="only-row">
      <InputText
        label="Nome Completo"
        isRequired
        v-model="form.name"
        :maxlength="100"
      />
    </div>
    <div class="three-row">
      <InputText label="Data de Nascimento" isRequired
        placeholder="DD/MM/YYYY"
        mask="##/##/####"
        v-model="form.birth"
      />
      <div class="radio-sex">
        <span class="label">Sexo <span style="color: #FF0000;">*</span></span>
        <div class="radio-input">
          <label style="margin-right: 1rem;">
            <input type="radio" v-model="form.sex" value="m" />Masculino
          </label>
          <label><input type="radio" v-model="form.sex" value="f" />Feminino</label>
        </div>
      </div>
      <InputText label="CPF" isDisabled
        mask="###.###.###-##"
        v-model="form.cpf"
      />
    </div>
    <div class="only-row">
      <InputText label="Nome da Mãe" isRequired
        v-model="form.mother"
      />
    </div>
    <div class="two-row">
      <InputText label="E-mail" isRequired
        v-model="form.email"
      />
      <InputText label="Telefone" isRequired
        placeholder="(DDD)99999-9999"
        mask="(##)#####-####"
        v-model="form.phone"
      />
    </div>
    <div class="line-row"></div>
    <div class="three-row">
      <InputText label="CEP"
        v-model="form.cep"
      />
      <InputText label="Logradouro"
        v-model="form.street"
      />
      <InputText label="Número"
        v-model="form.number"
      />
    </div>
    <div class="two-row">
      <InputText label="Bairro"
        v-model="form.neighborhood"
      />
      <InputText label="Município"
        v-model="form.city"
      />
    </div>
    <div class="line-row"></div>
    <div class="two-row">
      <InputText label="Nova Senha" isRequired
        v-model="form.newPsw"
      />
      <InputText label="Confirmar Nova Senha" isRequired
        v-model="form.confirmPsw"
      />
    </div>
  </section>
</template>

<script>
import moment from "moment";
import InputText from "@/components/input/text/InputText";
export default {
  name: "FormFirstAccess",
  components: { InputText },
  data() {
    return {
      patient: null,
      form: {
        name: null,
        birth: null,
        sex: null,
        cpf: null,
        mother: null,
        email: null,
        phone: null,
        cep: null,
        street: null,
        number: null,
        neighborhood: null,
        city: null,
        newPsw: null,
        confirmPsw: null
      }
    };
  },

  async mounted() {
    const storageUser = await this.$store.dispatch("getLocalStoragePatient")
    const patientFromHash = await this.$store.dispatch("getPatientToVideoUrl")
    this.patient = patientFromHash || storageUser
    if (this.patient) {
      this.form = {
        name: this.patient.pac_nome,
        birth: moment(this.patient.usu_nascimento, 'YYYY-MM-DD').format('DD/MM/YYYY'),
        sex: this.patient.usu_genero,
        cpf: this.patient.usu_cpf,
        mother: this.patient.usu_nome_mae,
        email: this.patient.usu_email,
        phone: this.patient.usu_telefone,
        cep: this.patient.usu_cep,
        street: this.patient.usu_logradouro,
        number: this.patient.usu_endereco_numero,
        neighborhood: this.patient.usu_bairro,
        city: this.patient.usu_municipio
      }
    }
  },

  computed: {
  },

  methods: {
    getPayload () {
      if (this.form.name.trim().length === 0) {
        return {
          status: false,
          msg: 'Nome do paciente é obrigatório.'
        }
      }

      var regEx = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~0-9]/
      if (regEx.test(this.form.name)) {
        return {
          status: false,
          msg: 'O Nome não pode conter números e nem letras especiais.'
        }
      }

      const split = this.form.name.split(' ')
      if (split.length < 2) {
        return {
          status: false,
          msg: 'O Nome deve conter nome e sobrenome.'
        }
      }
      if (split[0].length < 2) {
        return {
          status: false,
          msg: 'O Nome deve conter mais de 2 letras.'
        }
      }
      if (split[1].length < 2) {
        return {
          status: false,
          msg: 'O Sobrenome deve conter mais de 2 letras.'
        }
      }

      if (this.form.birth.length < 1) {
        return {
          status: false,
          msg: 'O campo Data de Nascimento é obrigatório'
        }
      }
      const birthMom = moment(this.form.birth, "DD/MM/YYYY");
      if (!birthMom.isValid()) {
        return {
          status: false,
          msg: 'O campo Data de Nascimento está inválido'
        }
      }
      if (birthMom.isAfter(moment())) {
        return {
          status: false,
          msg: 'O campo Data de Nascimento deve ser antes da Data atual'
        }
      }

      if (this.form.sex === null) {
        return {
          status: false,
          msg: 'O campo Sexo é obrigatório'
        }
      }

      if (!this.form.cpf) {
        return {
          status: false,
          msg: 'O campo CPF é obrigatório'
        }
      }
      if (this.form.cpf.length < 14) {
        return {
          status: false,
          msg: 'O campo CPF deve ter no mínimo 11 números'
        }
      }

      if (!this.form.mother) {
        return {
          status: false,
          msg: 'O campo Nome da Mãe é obrigatório'
        }
      }
      if (this.form.mother.length < 3) {
        return {
          status: false,
          msg: 'O campo Nome da Mãe deve ter no mínimo 3 letras'
        }
      }

      if (!this.form.email) {
        return {
          status: false,
          msg: 'O campo E-mail é obrigatório'
        }
      }
      const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      const emailValid = regexEmail.test(this.form.email)
      if (!emailValid) {
        return {
          status: false,
          msg: 'O campo E-mail está inválido'
        }
      }

      if (!this.form.phone) {
        return {
          status: false,
          msg: 'O campo Telefone é obrigatório'
        }
      }
      if (this.form.phone.length < 14) {
        return {
          status: false,
          msg: 'O campo Telefone está inválido'
        }
      }

      if (!this.form.newPsw) {
        return {
          status: false,
          msg: 'O campo Nova Senha é obrigatório'
        }
      }
      if (!this.form.confirmPsw) {
        return {
          status: false,
          msg: 'O campo Confirmar Nova Senha é obrigatório'
        }
      }
      if (!this.isValidPassword(this.form.newPsw)) {
        return {
          status: false,
          msg: 'A senha deve conter no mínimo oito caracteres e incluir pelo menos um caractere maiúsculo, um caractere minúsculo e um caractere especial.'
        }
      }
      if (this.form.confirmPsw !== this.form.newPsw) {
        return {
          status: false,
          msg: 'O campo Confirmar Nova Senha está diferente do campo Nova Senha'
        }
      }

      return {
        status: true,
        form: this.form
      }
    },


    // * VALIDACAO
    isValidPassword(password) {
      // Pelo menos um caractere maiúsculo
      const upperCaseRegex = /[A-Z]/;
      // Pelo menos um caractere minúsculo
      const lowerCaseRegex = /[a-z]/;
      // Pelo menos um caractere especial
      const specialCharacterRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
      // Mínimo de oito caracteres
      const lengthRegex = /.{8,}/;

      return (
        upperCaseRegex.test(password) &&
        lowerCaseRegex.test(password) &&
        specialCharacterRegex.test(password) &&
        lengthRegex.test(password)
      );
    },
  },
};
</script>
