<style src="./PatientHeader.scss" lang="scss" scoped></style>
<template>
  <section class="patient-header">
    <div class="logo" @click="onClickBack">
      <img class="img" :src="appLogoWhite" />
      <!-- {{ appName }} -->
    </div>
    <div class="user" :class="{ longPatientName: longPatientName }">
      {{ patient ? patient.pac_nome : "" }}
    </div>
    <div @click="onClickExit"><IconExit class="exit" /></div>
    <!-- *** *** *** -->
    <!-- MODAL LGPD -->
    <section class="lgpd-all" v-if="showLgpd">
      <section class="lgpd-content">
        <div class="l-header">Termo de Consentimento</div>

        <div v-if="consentForm" class="iframe-container" >
          <iframe :src="consentForm" allowtransparency="true" style="background: #FFFFFF;" frameborder="0" allowfullscreen allowTransparency="true"></iframe>
        </div>
        <div v-else class="l-text" v-html="termo"></div>

        <div class="l-footer">
          <PlButton label="Cancelar" gray cross @click="lgpdCancel" />
          <PlButton
            label="Concordar e Continuar"
            primary
            check
            @click="lgpdContinue"
          />
        </div>
      </section>
    </section>
    <!-- *** *** *** -->
    <!-- MODAL PRIMEIRO ACESSO -->
    <section class="first-access-all" v-if="showFirstAccess">
      <section class="first-access-content">
        <div class="fa-header">Complemento de Cadastro</div>
        <div class="fa-content">
          <FormFirstAccess ref="FormFirstAccess" />
        </div>
        <div class="fa-msg" v-if="msgFirstAccess">
          <div class="__text">{{ msgFirstAccess }}</div>
        </div>
        <div class="fa-footer">
          <PlButton label="Cancelar" gray cross @click="firstAccessCancel" />
          <PlButton
            label="Salvar e Continuar"
            primary
            check
            @click="firstAccessContinue"
          />
        </div>
      </section>
    </section>
    <!-- *** *** *** -->
    <!-- *** *** *** -->
  </section>
</template>

<script>
import { ServicoHttp } from "@/axios/servico-http";
import { ServicoAll } from "@/axios/servico-all";
import Firebase from "@/helpers/firebase/index";
import FirebaseLogs from "@/helpers/firebase_logs";
import moment from "moment";

import PlButton from "@/components/button/Button";
import IconExit from "@/assets/icons/IconExit";
import FormFirstAccess from "../form-first-access/FormFirstAccess";

import { html as htmlMedicar } from "./termoMedicar.js";
import { html as htmlAxia } from "./termoAxia.js";
import { html as htmlAma } from "./termoAma.js";
import { html as htmlDurga } from "./termoDurga.js";
import { html as htmlEmercor } from "./termoEmercor";
import { html as htmlTelehelp } from "./termoTelehelp";
import { html as htmlHomeangels } from "./termoHomeangels";
import { html as htmlGeneric } from "./termoGeneric.js";

export default {
  name: "PatientHeader",
  components: { PlButton, IconExit, FormFirstAccess },

  data() {
    return {
      qsSystemFrom: "",
      patient: null,
      pacId: null,
      browserInfo: null,
      showLgpd: false,
      showFirstAccess: false,
      msgFirstAccess: null,
      appName: process.env.VUE_APP_NAME
    };
  },

  computed: {
    longPatientName() {
      return this.patient && this.patient.pac_nome.length > 30;
    },
    termo() {
      const theme = process.env.VUE_APP_THEME;
      switch (theme.toUpperCase()) {
        case "MEDICAR":
          return htmlMedicar;
        case "AMA":
          return htmlAma;
        case "AXIA":
          return htmlAxia;
        case "EMERCOR":
          return htmlEmercor;
        case "DURGA":
          return htmlDurga;
        case "TELEHELP":
          return htmlTelehelp;
        case "HOMEANGELS":
          return htmlHomeangels;
        default:
          return htmlGeneric;
      }
    },
  },

  async mounted() {
    this.servicoHttp = new ServicoHttp(this);
    this.sPepPacUpTermo = new ServicoAll(
      "/api/pep/paciente/termo",
      this.servicoHttp
    );
    this.sFirstAcess = new ServicoAll(
      "api/pep/paciente/primeiro-acesso",
      this.servicoHttp
    );
    this.sGetInfoPatientByPatientId = new ServicoAll(
      "/api/pep/paciente/get-by-id",
      this.servicoHttp
    );

    const storageUser = await this.$store.dispatch("getLocalStoragePatient");
    const patientFromHash = await this.$store.dispatch("getPatientToVideoUrl");

    this.patient = patientFromHash || storageUser;
    // console.warn('[Header] storage', storageUser)
    // console.warn('[Header] hash', patientFromHash)
    this.pacId = this.patient.pac_id;
    let infoPatient = await this.sGetInfoPatientByPatientId.get({
      pac_id: this.pacId,
    });
    if (infoPatient.data.length > 0) {
      infoPatient = infoPatient.data[0];
      await this.$store.dispatch("setLocalStoragePatient", infoPatient);
      this.patient = { ...infoPatient };
    }

    this.qsSystemFrom = this.$route.query.from || "";

    if (!this.patient) {
      console.warn("[PatientHeader] Nao tem paciente");
      this.onClickExit();
      return;
    }

    if (!this.patient.pac_primeiro_acesso) {
      this.showFirstAccess = true;
    }
    if (!this.patient.pac_concorda_lgpd) {
      this.showLgpd = true;
    }

    await this.getPermissions();
  },

  methods: {
    onClickBack() {
      this.$router.push({ name: "pacienteHome" });
    },
    // *** *** ***
    firstAccessCancel() {
      this.onClickExit();
    },
    async firstAccessContinue() {
      const payload = this.$refs.FormFirstAccess.getPayload();
      if (payload.status === false) {
        this.msgFirstAccess = payload.msg;
        return;
      }
      this.msgFirstAccess = null;
      payload.form.pac_id = this.patient.pac_id;
      payload.form.usu_id = this.patient.pac_id_usuarios;
      payload.form.usu_cpf = this.patient.usu_cpf;
      await this.sFirstAcess.post(payload.form);
      this.patient.pac_primeiro_acesso = 1;

      this.patient.pac_nome = payload.form.name;
      (this.patient.usu_nascimento = moment(
        payload.form.birth,
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD")),
        (this.patient.usu_genero = payload.form.sex);
      this.patient.usu_cpf = payload.form.cpf;
      this.patient.usu_nome_mae = payload.form.mother;
      this.patient.usu_email = payload.form.email;
      this.patient.usu_telefone = payload.form.phone;
      this.patient.usu_cep = payload.form.cep;
      this.patient.usu_logradouro = payload.form.street;
      this.patient.usu_endereco_numero = payload.form.number;
      this.patient.usu_bairro = payload.form.neighborhood;
      this.patient.usu_municipio = payload.form.city;

      await this.$store.dispatch("setLocalStoragePatient", this.patient);
      this.patient = await this.$store.dispatch("getLocalStoragePatient");
      console.log("novo pac", this.patient);
      this.showFirstAccess = false;
    },
    // *** *** ***
    lgpdCancel() {
      this.onClickExit();
    },
    async lgpdContinue() {
      this.showLgpd = false;
      this.patient.pac_concorda_lgpd = 1;
      await this.sPepPacUpTermo.post({ pacId: this.pacId });
      await this.$store.dispatch("setLocalStoragePatient", this.patient);
      this.patient = await this.$store.dispatch("getLocalStoragePatient");
    },
    // *** *** ***
    async onClickExit() {
      // ! Firebase LOG
      const objLog = { ...this.patient };
      const timeAction = moment().format("YYYY-MM-DD HH:mm:ss");
      FirebaseLogs.saveLog(1102, timeAction, objLog);

      const isNull = await Firebase.isPacienteWithStatusNull(
        this.patient.pac_id
      );
      if (!isNull) {
        this.$toast.info(
          "Por favor, encerre esta ligação para sair do sistema."
        );
        return;
      }
      await Firebase.setFirestoreConfig();
      await Firebase.stopCallingCircle();
      await Firebase.removePacIdFromDocuments(this.pacId);
      await Firebase.updatePatientSpecificKey(this.pacId, "comando", "nenhum");
      await Firebase.updatePatientSpecificKey(this.pacId, "status", null); // 980
      await Firebase.removeVirtualQueueByPatientId(this.pacId);
      await Firebase.removeFilaVirtualMedicoByPacId(this.pacId);
      await this.$store.dispatch("removeLocalStoragePatient", null);
      this.$router.push({ name: "pacienteLogin" });
    },

    async update() {
      this.patient = await this.$store.dispatch("getLocalStoragePatient");
      if (!this.patient) {
        console.warn("[PatientHeader] Nao tem paciente");
        this.onClickExit();
      }
    },
    // * PERMISSAO DE VIDEO
    async getPermissions() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        });
        stream.getTracks().forEach((track) => track.stop());
      } catch (error) {
        console.warn("Erro ao solicitar permissão:", error);
        this.$toast.error("A Webcam/Microfone não estão liberados para o uso", {
          duration: 3000,
        });
      }
    },
  },
};
</script>
