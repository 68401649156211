<style src="./PatientHome.scss" lang="scss" scoped></style>
<template>
  <section class="patient-home">
    <Loading v-if="isLoading" />

    <!-- HEADER -->
    <PatientHeader />
    <!-- *** *** *** -->

    <!-- CONTENT -->
    <div class="content">
      <div class="warning" v-if="!browserInfo.eChrome">
        <i class="fas fa-exclamation-triangle" />
        <div class="txt">
          <div class="txt_title">ATENÇÃO!</div>
          <div class="txt_text">
            O seu navegador {{ browserInfo.nome }} poderá exibir instabilidades.
            Esse sistema não foi homologado neste navegador. Sugerimos a
            utilização do Google Chrome
          </div>
        </div>
      </div>

      <div class="warning" v-if="showWarn">
        <i class="fas fa-exclamation-triangle" />
        <div class="txt">
          <div class="txt_title">ATENÇÃO!</div>
          <div class="txt_text">
            O profissional ainda está na chamada. Você será encaminhado em instantes.
          </div>
        </div>
      </div>

      <div class="cards">
        <div v-if="hasPermissionsImmediateAssistance" class="card" @click="onClickSolAtd">
          <div class="txt">Solicitar Pronto Atendimento</div>
          <img class="icon" src="~@/assets/01.png" />
        </div>
        <div v-if="hasPermissionsRequestingAppointment" class="card" @click="onClickSchedule">
          <div class="txt">Solicitar <br />Agendamento</div>
          <img class="icon" src="~@/assets/02.png" />
        </div>
        <div v-if="!isLoading" class="card" @click="gotoPatientClinicalDocuments">
          <div class="txt">
            Documentos <br />
            Médicos Recebidos
          </div>
          <img class="icon" src="~@/assets/03.png" />
        </div>
        <div v-if="!isLoading" class="card" @click="onClickFiles">
          <div class="txt">Meus Documentos Enviados</div>
          <img class="icon" src="~@/assets/04.png" />
        </div>
        <div v-if="!isLoading" class="card" @click="onClickLinks">
          <div class="txt">Minhas Consultas Agendadas</div>
          <img class="icon" src="~@/assets/05.png" />
        </div>
        <div v-if="!isLoading" class="card" @click="patientEncounterHistory">
          <div class="txt">Histórico de Atendimento</div>
          <img class="icon" src="~@/assets/06.png" />
        </div>
        <div v-if="!isLoading" class="card" @click="onClickUserInfo">
          <div class="txt">Meus Dados</div>
          <img class="icon" src="~@/assets/07.png" />
        </div>
        <div v-if="!isLoading" class="card" @click="onClickGiftCard">
          <div class="txt">Gift Card</div>
          <img class="icon" :src="giftCardSvg" />
        </div>
      </div>

      <div>
        <div class="support" @click="onClickSupport">
          <IconSupport v-if="!isBtnMicroOn" />
          <div class="text">
            <div class="title">SUPORTE</div>
            <div class="subtitle">SUPORTE DE QUALIDADE</div>
          </div>
        </div>
        <span class="version"> Versão {{ appVersion }}</span>
      </div>
    </div>
    <!-- *** *** *** -->
  </section>
</template>

<script>
import moment from "moment";

import { ServicoHttp } from "@/axios/servico-http";
import { ServicoHttpCallingCircle } from "@/axios/servico-http-calling-circle";
import { ServicoAll } from "@/axios/servico-all";
import FirebaseLogs from "@/helpers/firebase_logs";

import Loading from "../components/loading/Loading";
import PatientHeader from "../components/header/PatientHeader";
import IconSupport from "@/assets/icons/IconSupport";
import Firebase from "@/helpers/firebase/index";

export default {
  name: "PatientHome",
  components: {
    PatientHeader,
    IconSupport,
    Loading,
  },

  data() {
    return {
      isLoading: true,
      showWarn: false,
      patient: null,
      browserInfo: {
        eChrome: true,
      },
      isBtnMicroOn: false,
      permissions: {
        showImmediateAssistance: false,
        showRequestingAppointment: false,
      },
      immediateAssistanceContract: [],
      appointmentSchedulingContract: [],
      rescuedGiftCard: '',
      totalPromptServiceQuotes: null,
      totalAppointmentQuotes: null,
    };
  },

  computed: {
    version() {
      return packageInfo.version;
    },
    hasPermissionsImmediateAssistance() {
      console.log('hasPermissionsImmediateAssistance')
      console.log(this.permissions.showImmediateAssistance, this.totalPromptServiceQuotes > 0, this.totalPromptServiceQuotes !== null)
      return this.permissions.showImmediateAssistance || (this.totalPromptServiceQuotes > 0 && this.totalPromptServiceQuotes !== null)
    },
    hasPermissionsRequestingAppointment() {
      console.log('hasPermissionsRequestingAppointment')
      console.log(this.permissions.showImmediateAssistance, this.totalAppointmentQuotes > 0, this.totalAppointmentQuotes !== null)
      return this.permissions.showRequestingAppointment || (this.totalAppointmentQuotes > 0 && this.totalAppointmentQuotes !== null)
    },
    isDifferentSystemaPA() {
      const from = process.env.VUE_APP_SYSTEM_FROM_PA
      const to = process.env.VUE_APP_SYSTEM_TO_PA
      return from.toUpperCase() !== to.toUpperCase()
    },
    objSystemFromTo() {
      const from = process.env.VUE_APP_SYSTEM_FROM_PA
      const to = process.env.VUE_APP_SYSTEM_TO_PA
      return {
        systemFrom: from.toUpperCase(),
        systemTo: to.toUpperCase()
      }
    }
  },

  async mounted() {
    this.servicoHttp = new ServicoHttp(this);
    this.servicoHttpCallingCircle = new ServicoHttpCallingCircle(this);
    this.patient = await this.$store.dispatch("getLocalStoragePatient");

    this.apiGetObjTokenByPatientId = new ServicoAll(
      "/solicitacao-atendimento/get-token-pacid",
      this.servicoHttpCallingCircle
    );

    this.apiCleanPatient = new ServicoAll(
      "/solicitacao-atendimento/limpar-paciente",
      this.servicoHttpCallingCircle
    );

    this.browserInfo = this.getBrowserInfo();
    await Firebase.setFirestoreConfig();
    await this.apiCleanPatient.post({
      patientId: this.patient.pac_id,
      cleanByPatient: true
    });

    await this.validatePermissionsPatient();
    // await this.validatePermissionsGiftCard();

    this.sPepCheckCurrentGiftCard = new ServicoAll(
      "/api/pep/gift-card/check-current-gift-card",
      this.servicoHttp
    );
    this.sPepGetPromptServiceQuotasByPacId = new ServicoAll(
      "/api/pep/gift-card/get-prompt-service-quotas-by-pac-id",
      this.servicoHttp
    );
    this.sPepGetAppointmentQuotasByPacId = new ServicoAll(
      "/api/pep/gift-card/get-appointment-quotas-by-pac-id",
      this.servicoHttp
    );

    await this.getCurrentGiftCard()
  },

  methods: {
    async getCurrentGiftCard() {
      const ret = await this.sPepCheckCurrentGiftCard.post({
        idPac: this.patient.pac_id,
      })

      if (ret.data.data.length > 0) {
        this.rescuedGiftCard = ret.data.data[0].gtc_codigo

        const retGetPromptServiceQuotasByPacId = await this.sPepGetPromptServiceQuotasByPacId.post({
          idPac: this.patient.pac_id,
        })

        if (retGetPromptServiceQuotasByPacId.data.status) {
          if (retGetPromptServiceQuotasByPacId.data.data.length > 0) {
            const qtdPrompt = retGetPromptServiceQuotasByPacId.data.data[0].quantidade_total
            this.totalPromptServiceQuotes = qtdPrompt
          }
        }

        const retGetAppointmentQuotasByPacId = await this.sPepGetAppointmentQuotasByPacId.post({
          idPac: this.patient.pac_id
        })

        if (retGetAppointmentQuotasByPacId.data.status) {
          if (retGetAppointmentQuotasByPacId.data.data.length > 0) {
            const qtdAppointment = retGetAppointmentQuotasByPacId.data.data[0].quantidade_total
            this.totalAppointmentQuotes = qtdAppointment
          }
        }
      }
    },

    async validatePermissionsPatient() {
      try {
        this.isLoading = true
        const ACTIVE_CONTRACT = "Ativo"

        const verifyPermissionsContractService = new ServicoAll("/api/pep/contracts/verify-permissions-contract", this.servicoHttp);
        const verifyPermissions = await verifyPermissionsContractService.get({ pac_id: this.patient.pac_id });
        const contracts = verifyPermissions?.data?.result

        if (contracts && contracts.length > 0) {
          let contractsWithinValidityPeriod = contracts.filter(contract => contract.statusContract === "Ativo");

          if (contractsWithinValidityPeriod.length < 1) {
            this.$toast.info("Contrato expirado. Contate o suporte.", { duration: 3000 })
            return
          }

          contractsWithinValidityPeriod.forEach(item => {
            if (item.hasPermissionImmediateAssistance) {
              this.permissions.showImmediateAssistance = true

              if (item.statusContract === ACTIVE_CONTRACT) {
                this.immediateAssistanceContract.push(item)
              }
            }

            if (item.hasPermissionAppointmentScheduling) {
              this.permissions.showRequestingAppointment = true

              if (item.statusContract === ACTIVE_CONTRACT) {
                this.appointmentSchedulingContract.push(item)
              }
            }
          })
        } else {
          this.$toast.info(verifyPermissions.message, { duration: 3000 })
        }
      } catch (error) {
        console.error("Não foi possível carregar as permissões.")
      } finally {
        this.isLoading = false
      }
    },

    async onClickSolAtd() {
      const hasNotGiftCard = (this.totalPromptServiceQuotes <= 0 || this.totalPromptServiceQuotes === null)
      const hasNotContract = (!this.immediateAssistanceContract || this.immediateAssistanceContract.length < 1)

      if (hasNotGiftCard && hasNotContract) {
        this.$toast.info("Você não possui contrato nem gift card.", { duration: 3000 })
        return
      }


      if (this.isDifferentSystemaPA) {
        if (this.objSystemFromTo.systemTo.toUpperCase() === 'MEDICAR') {
          const pacId = this.patient.pac_id
          let cpf = this.patient.usu_cpf
          cpf = cpf.replace(/[.-]/g, '')
          const url = `${process.env.VUE_APP_MEDICAR_SOLICITACAO_ATENDIMENTO}/portal-paciente/solicitar-pronto-atendimento?cpf=${cpf}&p=${pacId}&from=${this.objSystemFromTo.systemFrom}`;
          window.location.href = url;
        } else {
          console.error('[PatientHome]', 'abrir solicitacao atendimento', 'sistemas diferentes, mas nao medicar', this.objSystemFromTo)
        }
        return
      }
      // const existFun = await Firebase.existFuncionarioWithPacId(this.patient.pac_id)
      const isNull = await Firebase.isPacienteWithStatusNull(
        this.patient.pac_id
      );
      if (!isNull) {
        this.showWarn = true;
        setTimeout(async () => {
          const objToken = await this.apiGetObjTokenByPatientId.post({ patientId: this.patient.pac_id })
          console.log('objToken', objToken)
          const objTokenData = objToken.data.obj
          this.$router.push({
            name: "pacienteVideo",
            query: { video: objTokenData.spaId, refresh: true, token: objTokenData.token },
          });
          this.showWarn = false;
        }, 3 * 1000);
      } else {
        this.$router.push({ name: "pacienteServiceRequest", query: { cpf: this.patient.usu_cpf } });
      }
    },
    onClickSchedule() {
      const hasNotGiftCard = (this.totalAppointmentQuotes <= 0 || this.totalAppointmentQuotes === null)
      const hasNotContract = (!this.appointmentSchedulingContract || this.appointmentSchedulingContract.length < 1)

      if (hasNotGiftCard && hasNotContract) {
        this.$toast.info("Você não possui contrato nem gift card.", { duration: 3000 })
        return
      }

      this.$router.push({ name: "pacienteSchedule", params: { appointmentSchedulingContract: this.appointmentSchedulingContract } });
    },
    onClickFiles() {
      this.$router.push({ name: "pacienteFiles" });
    },
    gotoPatientClinicalDocuments() {
      this.$router.push({ name: "patientClinicalDocuments" });
    },
    patientEncounterHistory() {
      this.$router.push({ name: "patientEncounterHistory" });
    },
    onClickLinks() {
      this.$router.push({ name: "pacienteLinks" });
    },
    onClickUserInfo() {
      this.$router.push({ name: "pacienteUserInfo" });
    },
    onClickGiftCard() {
      this.$router.push({ name: "pacienteGiftCard" });
    },
    onClickSupport() {
      // ! Firebase LOG
      const objLog = { ...this.patient }
      const timeAction = moment().format('YYYY-MM-DD HH:mm:ss')
      FirebaseLogs.saveLog(1600, timeAction, objLog)

      const numWhatsApp = `+${process.env.VUE_APP_WHATSAPP_NUMBER}`;
      const numWhatsAppReplace = numWhatsApp.replace(/\s+/g, "")
      const urlWhatsApp = `https://wa.me/${numWhatsAppReplace}`;
      window.open(urlWhatsApp, "_blank");
    },
    // *** *** ***
    // *** *** ***
    getBrowserInfo() {
      const ua = navigator.userAgent;
      let nome = "Desconhecido";
      let versao = "Desconhecida";

      // Verificar se é o Google Chrome
      if (/Chrome/i.test(ua)) {
        nome = "Google Chrome";
      }
      // Verificar se é o Mozilla Firefox
      else if (/Firefox/i.test(ua)) {
        nome = "Mozilla Firefox";
      }
      // Verificar se é o Safari
      else if (/Safari/i.test(ua)) {
        nome = "Safari";
      }

      // Extrair a versão do navegador
      const matchVersao = ua.match(/(Chrome|Firefox|Safari)\/([\d.]+)/);
      if (matchVersao && matchVersao.length === 3) {
        versao = matchVersao[2];
      }

      return {
        nome: nome,
        versao: versao,
        eChrome: nome === "Google Chrome",
        eFirefox: nome === "Mozilla Firefox",
        eSafari: nome === "Safari",
        // Adicione mais verificações conforme necessário
      };
    },
  },
};
</script>
