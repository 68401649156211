import { initializeApp } from 'firebase/app'
import { getDatabase, ref, onValue, set } from 'firebase/database'

import moment from 'moment'
const codes = require('./dictionary.js')

let FirebaseDB = null
const pathLogs = `logs`

const setFirebaseConfig = () => {
  let config = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID
  }
  const FirebaseApp = initializeApp(config)
  FirebaseDB = getDatabase(FirebaseApp)
}

const getAllLogs = (functionCallback) => {
  setFirebaseConfig()
  const path = `${pathLogs}`
  onValue(ref(FirebaseDB, path), (snapshot) => {
    const data = snapshot.val()
    if (data === null) {
      functionCallback({ length: 0, list: [] })
      return
    }
    const array = Object.entries(data)
    const allLength = array.length
    const last = array.slice(-1000)
    const lastReverse = last.reverse()

    const lastObjeto = []
    for (let item of lastReverse) {
      lastObjeto.push({
        firebaseId: item[0],
        ...item[1]
      })
    }
    functionCallback({ length: allLength, list: lastObjeto })
  })
}

const saveLog = (code, timeAction, obj) => {
  const dictionaryCode = codes[code.toString()]
  // eslint-disable-next-line no-constant-condition
  if (true) {
    return
  }
  setFirebaseConfig()

  const key = moment().format('YYYYMMDD_HHmmssSSS')
  const newId = `${key}_Web_${code}`
  const objPayload = {
    created_at: moment().format('YYYY-MM-DD HH:mm:ss'),
    code: code,
    codeLabel: dictionaryCode,
    timeAction: timeAction,
    ...obj
  }
  set(ref(FirebaseDB, `${pathLogs}/${newId}`), objPayload)
    .then(() => {
      console.log('SAVE LOG', code, 'Armazenado com sucesso')
    })
    .catch((error) => {
      console.error('SAVE LOG', code, error)
    })
}

// *** *** ***
export default {
  setFirebaseConfig,
  saveLog,
  getAllLogs
}
