<style src="./PatientLogin.scss" lang="scss" scoped></style>
<template>
  <section class="home color-overlay">
    <Loading v-if="isLoading" />
    <!-- FORM -->
    <section class="back-form-box">
      <section
        class="form-box"
        v-if="
          !showModal &&
          !showModalComplementRegister &&
          !showModalAcceptTerm &&
          !showModalTerm &&
          !showModalPriv
        "
      >
        <!-- <div class="title">{{ appUser }}</div> -->
        <img class="logo" :src="appLogo" />

        <div class="cpf" @keyup.enter="onActAccess">
          <InputText
            label="CPF"
            placeholder="Digite aqui o CPF"
            mask="###.###.###-##"
            v-model="inputCpf"
          />
        </div>

        <div class="cpf" @keyup.enter="onActAccess">
          <InputText
            type="password"
            label="Senha"
            placeholder="Digite aqui a Senha"
            v-model="inputPsw"
          />
        </div>
        <button class="btn-link margin-left-auto" @click="onActForgotPsw">Esqueci a senha</button>

        <div class="act">
          <div class="msg">{{ msgLogin }}</div>
          <div class="btn" @click="onActAccess">Entrar</div>
        </div>
        <div class="act">
          <div class="btn-secondary" @click="openModal">
            Inserir código de Gift Card
          </div>
        </div>

        <div v-if="isToastVisible" class="toast" :style="toastStyle">
          {{ toastMessage }}
        </div>

        <!-- Foi solicitado no caso #868 manter a versão na tela de login, sendo assim, modificar conforme for liberando versões para o cliente -->
        <div v-if="!showFooter" class="footer-login">
          <button v-if="consentForm" class="btn-link" @click="openModalTerm">Termo de uso</button>
          <button v-if="privacyForm" class="btn-link" @click="openModalPriv">Politica de Privacidade</button>
        </div>
      </section>
    </section>

    <div id="gift-card-modal" v-if="showModal">
      <div class="modal-header">
        <!-- retirar essa logo pois e da medicar -->
        <img
          src="../../../assets/gift_card.svg"
          height="22"
          width="22"
          alt=""
        />
        <span>Resgatar Gift Card</span>
      </div>
      <div class="modal-dialog">
        <InputText
          class="modal-input"
          label="Código do Gift Card"
          required
          isRequired
          placeholder="Digite aqui o código do Gift Card"
          maxlength="12"
          v-model="inputGiftCard"
        />
        <InputText
          class="modal-input"
          label="CPF"
          required
          isRequired
          placeholder="Digite aqui o CPF do paciente"
          mask="###.###.###-##"
          v-model="inputCpfForGiftCard"
        />
      </div>
      <div class="modal-footer">
        <PlButton
          label="Cancelar"
          class="pl-btn"
          cancel
          gray
          @click="closeModal"
        />
        <PlButton
          label="Salvar e Continuar"
          class="pl-btn"
          primary
          @click="onClickSendGiftCard"
        />
      </div>
    </div>

    <div class="complement-register-modal" v-if="showModalComplementRegister">
      <div class="complement-register-modal_content">
        <div class="modal-header">
          <span>Complemento do Cadastro</span>
        </div>
        <div class="modal-dialog">
          <TextInput
            label="Nome Completo"
            class="modal-input-col-12"
            required
            placeholder="Digite seu nome completo"
            maxlength="100"
            v-model="formComplementRegister.name"
          />

          <div class="row-cpf">
            <TextInputDate
              class="ui-input modal-input-col-4"
              label="Nascimento"
              placeholder=""
              required
              mask="##/##/####"
              :today="false"
              v-model="formComplementRegister.birthDate"
              @keyup.enter="saveNewPatient"
            />

            <div class="modal-input-col-4">
              <span class="label"
                >Sexo <span style="color: #ff0000">*</span></span
              >
              <div class="radio-input">
                <label>
                  <input
                    type="radio"
                    v-model="formComplementRegister.gender"
                    value="m"
                  />Masculino</label
                >
                <label>
                  <input
                    type="radio"
                    v-model="formComplementRegister.gender"
                    value="f"
                  />Feminino</label
                >
              </div>
            </div>

            <TextInput
              class="ui-input modal-input-col-4"
              disabled
              label="CPF"
              placeholder="Digite aqui o CPF do paciente"
              mask="###.###.###-##"
              v-model="inputCpfForGiftCard"
              @keyup.enter="saveNewPatient"
            />
          </div>
          <div class="row">
            <TextInput
              class="ui-input modal-input-col-12"
              label="Nome da mãe"
              placeholder="Digite o da sua mãe"
              required
              maxlength="100"
              v-model="formComplementRegister.motherName"
              @keyup.enter="saveNewPatient"
            />
          </div>
          <div class="row">
            <TextInput
              class="ui-input modal-input-col-6"
              label="E-mail"
              required
              maxlength="100"
              placeholder="Digite seu e-mail"
              v-model="formComplementRegister.email"
              @keyup.enter="saveNewPatient"
            />
            <TextInput
              class="ui-input modal-input-col-6"
              label="Telefone"
              mask="(##) #####-####"
              required
              placeholder="Digite seu telefone"
              v-model="formComplementRegister.phone"
              @keyup.enter="saveNewPatient"
            />
          </div>
          <hr class="modal-input-col-12 hr" />

          <div class="row-cep">
            <TextInput
              class="modal-input-col-4-cep"
              label="Cep"
              maxlength="10"
              placeholder="Digite seu cep"
              required
              v-model="formComplementRegister.zipcode"
              @keyup.enter="saveNewPatient"
              @blur="searchAddress"
              mask="##.###-###"
            />
            <TextInput
              class="modal-input-col-4-cep"
              label="Logradouro"
              maxlength="100"
              required
              placeholder="Digite seu logradouro"
              v-model="formComplementRegister.address"
              @keyup.enter="saveNewPatient"
            />
            <TextInput
              class="modal-input-col-4-cep"
              label="Número"
              maxlength="200"
              required
              placeholder="Digite o número do seu logradouro"
              v-model="formComplementRegister.number"
              @keyup.enter="saveNewPatient"
            />
          </div>
          <div class="row">
            <TextInput
              class="ui-input modal-input-col-6"
              label="Bairro"
              maxlength="100"
              required
              placeholder="Digite seu bairro"
              v-model="formComplementRegister.neighborhood"
              @keyup.enter="saveNewPatient"
            />
            <TextInput
              class="ui-input modal-input-col-6"
              label="Município"
              v-model="formComplementRegister.city"
              required
              maxlength="100"
              @keyup.enter="saveNewPatient"
              placeholder="Digite seu município"
            />
          </div>
          <hr class="modal-input-col-12 hr" />
          <div class="row">
            <TextInput
              class="ui-input modal-input-col-6"
              label="Senha"
              required
              maxlength="42"
              placeholder="Digite sua senha"
              v-model="formComplementRegister.newpassword"
              @keyup.enter="saveNewPatient"
            />
            <TextInput
              class="ui-input modal-input-col-6"
              label="Confirmação de senha"
              required
              maxlength="42"
              placeholder="Digite a confirmação de senha"
              v-model="formComplementRegister.newpasswordconfirmation"
              @keyup.enter="saveNewPatient"
            />
          </div>
        </div>
        <div class="modal-footer">
          <PlButton
            label="Cancelar"
            class="pl-btn"
            cancel
            gray
            @click="closeModalComplementRegister"
          />
          <PlButton
            label="Salvar"
            class="pl-btn"
            check
            primary
            @click="saveNewPatient"
          />
        </div>
      </div>
    </div>

    <div id="accept-term-modal" v-if="showModalAcceptTerm">
      <div class="accept-term-modal_content">
        <div class="modal-header">
          <h2>Termo de Consentimento</h2>
        </div>
        <div class="modal-dialog">
          <component :is="termComponentName" />
        </div>
        <div class="modal-footer">
          <PlButton
            label="Cancelar"
            class="pl-btn"
            cancel
            gray
            @click="closeModalAcceptTerm"
          />
          <PlButton
            label="Concordar e Continuar"
            class="pl-btn"
            check
            primary
            @click="newAcceptTerm"
          />
        </div>
      </div>
    </div>

    <div id="accept-term-modal" v-if="showModalPriv">
      <div class="accept-term-modal_content">
        <div class="modal-header">
          <h2>Política de Privacidade</h2>
        </div>
        <div class="modal-dialog">
          <PrivacyTermIframe />
        </div>
        <div class="modal-footer">
          <PlButton
            label="Fechar"
            class="pl-btn"
            check
            primary
            @click="closeModalPriv"
          />
        </div>
      </div>
    </div>

    <div id="accept-term-modal" v-if="showModalTerm">
      <div class="accept-term-modal_content">
        <div class="modal-header">
          <h2>Termos de Uso</h2>
        </div>
        <div class="modal-dialog">
          <component :is="termComponentName" />
        </div>
        <div class="modal-footer">
          <PlButton
            label="Fechar"
            class="pl-btn"
            check
            primary
            @click="closeModalTerm"
          />
        </div>
      </div>
    </div>

    <section class="version-area">
      <span class="version">Versão {{ appVersion }}</span>
    </section>

    <section
      class="container-support"
      v-if="!showModalAcceptTerm && !showModalComplementRegister && !showFooter"
    >
      <div class="support" @click="support">
        <IconSupport v-if="!isBtnMicroOn" />
        <div class="text">
          <div class="title">SUPORTE</div>
          <div class="subtitle">SUPORTE DE QUALIDADE</div>
        </div>
      </div>
    </section>
    <!-- *** -->
    <div class="footer" v-show="showFooter">
      <div class="footer-content">
        <img
          src="../../../assets/logo_saude_vida.png"
          alt="SaúdeVida Logo"
          class="logo"
        />
      <div class="links">
        <a @click="openModalPriv">Política de Privacidade</a>
        <a @click="openModalTerm">Termos de Uso</a>
      </div>
        <div class="contact" @click="support">
          <p class="number">0800 724 3838</p>
          <p>(Telefone e Whatsapp)</p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import { ServicoHttp } from "@/axios/servico-http";
import { ServicoAll } from "@/axios/servico-all";
import { ServicoHttpCallingCircle } from "@/axios/servico-http-calling-circle";

import Firebase from "@/helpers/firebase/index";
import FirebaseLogs from "@/helpers/firebase_logs";
import moment from "moment";

import Loading from "../components/loading/Loading";
import IconSupport from "@/assets/icons/IconSupport";
import InputText from "@/components/input/text/InputText";
import { TextInput, TextInputDate } from "@/components/inputs";
import PlButton from "@/components/button/Button";
import TermMedicar from "@/components/term/TermMedicar";
import TermAxia from "@/components/term/TermAxia";
import ConsentTermIframe from "@/components/term/ConsentTermIframe";
import PrivacyTermIframe from "@/components/term/PrivacyTermIframe";
import Validations from "./helpers/Validations";

import { ValidateCPF } from "@/helpers/validations";

export default {
  name: "PortalPatientLogin",
  components: {
    InputText,
    IconSupport,
    PlButton,
    TextInputDate,
    TextInput,
    PrivacyTermIframe,
    ConsentTermIframe,
    TermMedicar,
    TermAxia,
    Loading,
  },

  data() {
    return {
      inputCpf: null,
      inputPsw: null,
      msgLogin: "",
      isBtnMicroOn: false,
      showModalTerm: false,
      showModalPriv: false,

      // Gift Card Auth
      showModal: false,
      isLoading: false,
      inputCpfForGiftCard: null,
      inputGiftCard: null,
      msgGiftCard: "",

      //Complemento de Cadastro
      showModalComplementRegister: false,
      formComplementRegister: {
        name: null,
        birthDate: null,
        gender: null,
        motherName: null,
        email: null,
        phone: null,
        zipcode: null,
        address: null,
        number: null,
        neighborhood: null,
        city: null,
        cpf: null,
        newpassword: null,
        newpasswordconfirmation: null,
      },
      isToastVisible: false,
      toastStyle: null,
      toastMessage: "",

      pacIdGiftCard: null,

      showModalAcceptTerm: false,
      clickAcceptTerm: false,

      appUser: process.env.VUE_APP_NAME,
      showFooter: process.env.VUE_APP_THEME.toUpperCase() === `MAXPLANET`,
    };
  },

  mounted() {
    this.servicoHttp = new ServicoHttp(this);
    this.servicoHttpCallingCircle = new ServicoHttpCallingCircle(this);

    this.sPepPacLogin = new ServicoAll(
      "/api/pep/paciente/login",
      this.servicoHttp
    );

    this.sPepAuthGiftCard = new ServicoAll(
      "/api/pep/gift-card/auth-gift-card",
      this.servicoHttp
    );

    this.sPepInsertNewPatientFromGiftCard = new ServicoAll(
      "/api/pep/gift-card/insert-new-patient-from-gift-card",
      this.servicoHttp
    );

    this.sPepAcceptTermGiftCard = new ServicoAll(
      "/api/pep/gift-card/accept-term",
      this.servicoHttp
    );

    this.sPepCheckCpfExistsInUsuarios = new ServicoAll(
      "/api/pep/gift-card/check-cpf-exists-in-usuarios",
      this.servicoHttp
    );

    this.sPepLinkGiftCard = new ServicoAll(
      "/api/pep/gift-card/link",
      this.servicoHttp
    );

    this.apiCleanPatient = new ServicoAll(
      "/solicitacao-atendimento/limpar-paciente",
      this.servicoHttpCallingCircle
    );

    if (this.$route.query?.message) {
      return this.$toast.warning(this.$route.query.message);
    }
  },

  methods: {
    support() {
      const numWhatsApp = `+${process.env.VUE_APP_WHATSAPP_NUMBER}`;
      const urlWhatsApp = `https://wa.me/${numWhatsApp.replace(/\s+/g, "")}`;
      window.open(urlWhatsApp, "_blank");
    },

    onActForgotPsw() {
      this.$router.push({ name: "forgotPsw" });
    },

    async onActAccess() {
      try {
        if (!this.inputCpf) {
          this.msgLogin = "CPF não informado";
          console.warn("CPF não informado");
          return;
        }
        if (!this.inputPsw) {
          this.msgLogin = "Senha não informada";
          console.warn("Senha não informada");
          return;
        }
        this.isLoading = true
        const obj = {
          cpf: this.inputCpf.replace(/[.-]/g, ""),
          psw: this.inputPsw,
        };
        const ret = await this.sPepPacLogin.post(obj);
        if (ret.data.status) {
          const user = ret.data.user;
          // ! Firebase LOG
          const objLog = { ...user };
          const timeAction = moment().format("YYYY-MM-DD HH:mm:ss");
          FirebaseLogs.saveLog(1101, timeAction, objLog);

          // console.log(user);
          await this.$store.dispatch("setLocalStoragePatient", user);
          await Firebase.setFirestoreConfig();
          const momNow = moment();
          const momBirth = moment(user.usu_nascimento, "YYYY-MM-DD");
          const age = momNow.diff(momBirth, "years");
          await Firebase.addPatient({
            pacId: user.pac_id,
            pacNome: user.pac_nome,
            pacGenero: user.usu_genero === "m" ? "Masculino" : "Feminino",
            pacIdade: age,
            pacImagem: user.usu_imagem_url,
            usuCpf: user.usu_cpf || "Não Informado",
          });

          await this.apiCleanPatient.post({
            patientId: user.pac_id,
            cleanByPatient: true,
          });

          this.$router.push({ name: "pacienteHome" });
        } else {
          throw new Error(ret.data.msg);
        }
      } catch (e) {
        this.msgLogin = e.message
      } finally {
        this.isLoading = false;
      }
    },
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },

    openModalTerm() {
      this.showModalTerm = true;
    },
    closeModalTerm() {
      this.showModalTerm = false;
    },

    openModalPriv() {
      this.showModalPriv = true;
    },
    closeModalPriv() {
      this.showModalPriv = false;
    },
    openModalComplementRegister() {
      this.showModalComplementRegister = true;
    },
    closeModalComplementRegister() {
      this.showModalComplementRegister = false;
    },
    openModalAcceptTerm() {
      this.showModalAcceptTerm = true;
    },
    closeModalAcceptTerm() {
      this.showModalAcceptTerm = false;
    },
    acceptTerm() {
      this.acceptTerm = true;
    },

    async loginWithGiftCard(userData) {
      if (userData) {
        const timeAction = moment().format("YYYY-MM-DD HH:mm:ss");
        FirebaseLogs.saveLog(1101, timeAction, userData);

        await this.$store.dispatch("setLocalStoragePatient", userData);
        await Firebase.setFirestoreConfig();
        const momNow = moment();
        const momBirth = moment(userData.usu_nascimento, "YYYY-MM-DD");
        const age = momNow.diff(momBirth, "years");
        await Firebase.addPatient({
          pacId: userData.pac_id,
          pacNome: userData.pac_nome,
          pacGenero: userData.usu_genero === "m" ? "Masculino" : "Feminino",
          pacIdade: age,
          pacImagem: userData.usu_imagem_url,
          usuCpf: userData.usu_cpf || "Não Informado",
        });

        await this.apiCleanPatient.post({
          patientId: userData.pac_id,
        });

        this.$router.push({ name: "pacienteHome" });
      } else {
        console.warn(ret.data.msg);
        this.msgLogin = ret.data.msg;
      }
    },

    async onClickSendGiftCard() {
      this.msgGiftCard = "";

      if (!this.inputGiftCard) {
        this.$toast.info("Gift Card não informado.", { duration: 3000 });

        return;
      }
      // if (!this.inputGiftCard) {
      //   this.$toast.info("O Gift Card informado não existe.", { duration: 3000 })
      //   return;
      // }
      if (!this.inputCpfForGiftCard) {
        this.$toast.info("CPF não informado.", { duration: 3000 });

        return;
      }
      if (this.inputCpfForGiftCard.length < 14) {
        this.$toast.info("CPF inválido.", { duration: 3000 });

        return;
      }

      if (!ValidateCPF(this.inputCpfForGiftCard)) {
        return this.$toast.info("CPF inválido.", { duration: 3000 });
      }

      const payloadAuthGiftCard = {
        cpf: this.inputCpfForGiftCard.replace(/[.-]/g, ""),
        giftCardCode: this.inputGiftCard,
      };

      const ret = await this.sPepAuthGiftCard.post(payloadAuthGiftCard);

      console.log("RET", ret);

      if (ret.data.message) {
        this.$toast.warning(ret.data.message, { duration: 3000 });
        if (ret.data.message == "Usuário não encontrado") {
          this.closeModal();
          this.closeModalAcceptTerm();
          // this.showModalComplementRegister = true;
          this.showModalAcceptTerm = true;
          return;
        }
        return;
      }

      if (ret.data.status) {
        this.closeModal();
        this.closeModalComplementRegister();
        this.closeModalAcceptTerm();
        this.$toast.success(
          "Seu cadastro foi concluído com sucesso. Acesse com o seu login e senha para utilizar",
          { duration: 3000 }
        );

        await this.sPepLinkGiftCard.post({
          cpf: this.inputCpfForGiftCard.replace(/[.-]/g, ""),
          giftCard: this.inputGiftCard,
        });

        const userData = ret.data.data[0];
        // this.loginWithGiftCard(userData); // issue#1376
        return;
      }
    },
    async saveNewPatient() {
      if (this.clickAcceptTerm === true) {
        this.formComplementRegister.cpf = this.inputCpfForGiftCard.replace(
          /[.-]/g,
          ""
        );

        if (!this.validations()) {
          return;
        }

        const ret = await this.sPepInsertNewPatientFromGiftCard.post(
          this.formComplementRegister
        );
        this.pacIdGiftCard = ret.data.data.patResult[0];

        this.closeModal();
        this.closeModalComplementRegister();
        // this.openModalAcceptTerm()
        this.finishComplementRegister();
        this.formComplementRegister = {};
      } else {
        this.$toast.warning("O termo de serviço não foi aceito.", {
          duration: 3000,
        });
      }
    },

    newAcceptTerm() {
      this.clickAcceptTerm = true;
      this.closeModal();
      this.closeModalAcceptTerm();
      this.openModalComplementRegister();
    },
    async finishComplementRegister() {
      const ret = await this.sPepAcceptTermGiftCard.post({
        pacId: this.pacIdGiftCard,
      });

      if (ret.data.status) {
        this.closeModal();
        this.closeModalComplementRegister();
        this.closeModalAcceptTerm();
        this.$toast.success("Seu cadastro foi concluído com sucesso.", {
          duration: 3000,
        });

        const payloadAuthGiftCard = {
          cpf: this.inputCpfForGiftCard.replace(/[.-]/g, ""),
          giftCardCode: this.inputGiftCard,
        };

        const authGiftCard = await this.sPepAuthGiftCard.post(
          payloadAuthGiftCard
        );

        if (authGiftCard.data.status) {
          await this.sPepLinkGiftCard.post({
            cpf: this.inputCpfForGiftCard.replace(/[.-]/g, ""),
            giftCard: this.inputGiftCard,
          });

          const userData = authGiftCard.data.data[0];
          // this.loginWithGiftCard(userData); // issue#1376
        }
      } else {
        alert("Erro ao aceitar o termo de uso, tente novamente mais tarde.");
      }
    },

    async searchAddress() {
      console.log("searchAddress");
      const zipcode = this.formComplementRegister.zipcode.replace(/[.-]/g, "");

      try {
        const data = await axios.get(
          `https://viacep.com.br/ws/${zipcode}/json/`
        );
        console.log(data);
        this.formComplementRegister.address = data.data.logradouro;
        this.formComplementRegister.neighborhood = data.data.bairro;
        this.formComplementRegister.city = data.data.localidade;
      } catch (error) {
        console.error("Erro ao buscar CEP:", error);
      }
    },

    validations() {
      const today = moment().format("YYYY-MM-DD");

      return Validations.validateAll(this, this.formComplementRegister, today);
    },
  },
};
</script>
