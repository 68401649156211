<style src="./PatientEncounterHistory.scss" lang="scss" scoped></style>
<template>
  <section class="patient-encounter-history">
    <PatientHeader />

    <section class="title">
      <span :style="{ 'text-decoration': 'underline', cursor: 'pointer' }" @click="routeBack">
        Início
      </span>
      <IconArrow />
      <span>Histórico de Atendimento</span>
    </section>

    <section class="content">
      <section class="input-filter">
        <TextInputDate
          :disabledDate="disableFutureDates"
          label="Data do Atendimento"
          mask="##/##/####"
          v-model="filter.date"
          class="input-button date"
        />
        <TextInput
          class="input-button professional"
          label="Profissional"
          v-model="filter.prof"
        />
        <PlButton :title="'Limpar'" noLine orange clean class="btn-clean" @click="cleanFilter" />
        <PlButton :title="'Buscar'" noLine blue search class="btn-search" @click="filterSearch" />
      </section>

      <div class="table-container">
        <table class="table">
          <thead>
            <tr class="th red">
              <th>Status do Atendimento</th>
              <th>Especialidade</th>
              <th>Profissional</th>
              <th>Data</th>
              <th>Hora</th>
              <th>Anexos</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="historyList.length === 0">
              <td colspan="4" class="empty-message">
                <IconNotFoundDocuments :style="{ width: '240px' }" />
                <br />
                <span>Nenhum dado encontrado no momento. </span>
                <br />
                <span>Tente uma nova pesquisa.</span>
              </td>
            </tr>
            <tr v-else class="td" v-for="(item, idx) of historyList" :key="`hist-${idx}`"
              :class="{ gray: idx % 2 === 1 }">
              <td>{{ item.status }}</td>
              <td>{{ item.fun_ocupacao_nome }}</td>
              <td>{{ item.fun_nome }}</td>
              <td>{{ item.data }}</td>
              <td>{{ item.hora }}</td>
              <td> <a target="_blank" :href="item.mvd_documento_url"> <img v-if="item.mvd_documento_url" src="../../../assets/anexo.svg" alt=""> </a> </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>

    <section class="footer">
      <PlButton label="" primary arrowLeft @click="routeBack" />
    </section>
  </section>
</template>

<script>
import moment from "moment";
import { ServicoHttp } from "@/axios/servico-http";
import { ServicoAll } from "@/axios/servico-all";
import IconArrow from "@/assets/icons/IconArrow";
import IconNotFoundDocuments from "@/assets/icons/IconNotFoundDocuments";

import PatientHeader from "../components/header/PatientHeader";

import { TextInput, TextInputDate } from "@/components/inputs";
import PlButton from "@/components/button/Button";
import SelectInput from "@/components/select-input/SelectInput.vue";

export default {
  name: "PatientEncounterHistory",
  components: {
    SelectInput,
    PlButton,
    TextInput,
    PatientHeader,
    TextInputDate,
    IconArrow,
    IconNotFoundDocuments,
    PlButton
  },

  watch: {},

  data() {
    return {
      patient: null,
      historyList: [],
      historyListAux: [],
      filter: {
        date: null,
        prof: null,
      },
    };
  },

  async mounted() {
    this.servicoHttp = new ServicoHttp(this);
    this.encounterHistory = new ServicoAll(
      "/api/pep/historico-atendimento/getHistorico",
      this.servicoHttp
    );
    this.patient = await this.$store.dispatch("getLocalStoragePatient");
    this.searhHistory();
  },

  methods: {
    async searhHistory() {
      const result = await this.encounterHistory.post({
        IdPatient: this.patient.pac_id,
      });
      this.historyListAux = [];
      if (result.data.status) {
        this.historyListAux = result.data.data;
      }
      this.historyList = Object.assign([], this.historyListAux);
    },

    filterSearch() {
      this.historyList = Object.assign([], this.historyListAux);

      const dateFilter = this.filter.date
        ? moment(this.filter.date, "YYYY-MM-DD").format("DD/MM/YYYY")
        : null;

      console.log(this.filter)

      const employeeFilter = this.filter.prof
        ? this.filter.prof.toLowerCase()
        : null;

      if (dateFilter && employeeFilter) {
        const auxDate = this.historyList.filter((obj) => {
          return obj.dataFormatada.includes(dateFilter);
        });
        const auxProf = auxDate.filter((obj) => {
          if (obj.fun_nome) {
            return obj.fun_nome.toLowerCase().includes(employeeFilter);
          }
        });
        this.historyList = auxProf;
      } else if (dateFilter) {
        this.historyList = this.historyList.filter((obj) => {
          return obj.dataFormatada.includes(dateFilter);
        });
      } else if (employeeFilter) {
        this.historyList = this.historyList.filter((obj) => {
          if (obj.fun_nome) {
            return obj.fun_nome.toLowerCase().includes(employeeFilter);
          }
        });
      } else {
        if (!this.historyList || this.historyList.length < 1) {
          this.filter = {
            date: null,
            prof: null,
          };
        }
        this.historyList = Object.assign([], this.historyListAux);
      }

      if (!this.historyList || this.historyList.length < 1) {
        this.filter = {
          date: null,
          prof: null,
        };
      }
    },

    disableFutureDates(pDate) {
      const today = new Date();
      return pDate > today;
    },

    cleanFilter() {
      this.filter.date = null;
      this.filter.prof = null;
      this.searhHistory();
    },

    routeBack() {
      this.$router.push({ name: "pacienteHome" });
    },
  },
};
</script>
