module.exports = {
   // PACIENTE
   '1101': 'Login do paciente',
   '1102': 'Saída do Sistema',
   '1200': 'Abertura tela Solicitar Pronto Atendimento',
   '1201': 'Solicitar Pronto Atendimento',
   '1202': 'Cancelar Solicitação Pronto Atendimento',
   '1300': 'Abertura Solicitar Agendamento',
   '1301': 'Solicitar Agendamento',
   '1302': 'Cancelar Solicitação Agendamento',
   '1400': 'Abertura Minhas Consultas Agendadas',
   '1401': 'Abrir Link da Consulta',
   '1402': 'Cancelar Agendamento',
   '1403': 'Reenviar link do Agendamento',
   '1500': 'Abertura Meus Dados',
   '1501': 'Salvar Meus Dados',
   '1502': 'Cancelar Meus Dados',
   '1600': 'Clique Botão de Suporte',
   '1700': 'Abertura Tela de Vídeo',
   '1701': 'Cancelar Vídeochamada',
   '1702': 'Ligar Câmera',
   '1703': 'Desligar Câmera',
   '1704': 'Ligar Microfone',
   '1705': 'Desligar Microfone',
   '1706': 'Solicitar permissão de câmera e microfone',
   '1707': 'Sem permissão para camera e microfone',
   '1708': 'Profissional entrou na sala',
   '1709': 'Profissional saiu da sala',
   '1710': 'Envio msg de chat',
   '1711': 'Recebimento de mensagem de chat',
   '1712': 'Atualização do lado do paciente',
   '1713': 'Atualização do lado do profissional',
   '1714': 'Entrou na fila de Requisição',
   '1715': 'Entrou no Calling Circle',
   '1716': 'Entrou na Fila Médica',
   '1717': 'Encaminhado para médico',
   '1800': 'Abertura Tela NPS',
   '1801': 'Cadastro de NPS',
   '1802': 'Cancelamento do NPS',
   // ASSISTENCIAL
   '2101': 'Login Profissional Assistencial',
   '2200': 'Abertura da Tela Principal',
   '2201': 'Toggle Profissional ON',
   '2202': 'Toggle Profissional Off',
   '2301': 'Comando Deslogar',
   '2302': 'Comando estaComTelaAtiva pergunta',
   '2303': 'Comando estaComTelaAtiva resposta',
   '2401': 'Status showModalLigacao',
   '2402': 'Status livre',
   '2403': 'Status deslogar',
   '2501': 'Comando msgAlert_remove ativo',
   '2601': 'Ação Modal de Alerta de Remoção: confirmar',
   '2602': 'Ação Modal de Ligação: ficar offline',
   '2603': 'Ação Modal de Ligação: atender',
   '2604': 'Ação Modal de Ligação: desativar som',
   '2605': 'Ação Modal de Ligação: ativar som',
   '2701': 'Atender agendamento',
   '2702': 'Editar agendamento',
   '2703': 'Cancelar agendamento',
   '2704': 'Criar agendamento: agendar paciente',
   '2705': 'Criar agendamento: bloquear horário',
   '2801': 'Tocando ligação',
   '2802': 'Atender Ligação: ARM',
   '2803': 'Atender Ligação: Médico',
   '2804': 'Abertura de Vídeochamada',
   '2805': 'Cancelar Vídeochamada',
   '2806': 'Ligar Câmera',
   '2807': 'Desligar C6amera',
   '2808': 'Ligar Microfone',
   '2809': 'Desligar Microfone',
   '2810': 'Solicitar permissão de câmera e microfone',
   '2811': 'Sem permissão para câmera e microfone',
   '2812': 'Paciente entrou na sala',
   '2813': 'Paciente saiu da sala',
   '2814': 'Envio de menssagem de chat',
   '2815': 'Recebimento de mensagem de chat',
   '2816': 'Atualizar chamada de vídeo pelo botão azul',
   '2817': 'Atualização tela do profissional',
   '2818': 'Saída do Sistema Assistencial [Logout]',
   '2819': 'Painel de Controle - Comando deslogar',
   '2820': 'Painel de Controle - Comando msgAlert_remove',
   // ASSISTENCIAL - Encaminhamento
   '2901': 'Encaminhado para Médico Específico',
   '2902': 'Encaminhado para Plantão Médico',
   // ASSISTENCIAL - ESI ou SOAP
   '3001': 'Abrir Escuta Inicial',
   '3002': 'Finalizar Escuta Inicial',
   '3101': 'Abrir SOAP',
   '3102': 'Finalziar SOAP',
   // GERENCIAL
   '4001': 'login do profissional gerencial',
   // CCAPI - SolAtd
   '8000': 'Requisitando Pronto Atendimento',
   '8001': 'Requisitou novamente, mas já está na fila',
   '8002': 'Requisitou novamente, mas já está na fila do médico',
   '8003': 'Entrou na Fila de Requisição',
   '8004': 'Cancelando Ligação',
   '8005': 'Atendendo Ligação',
   '8006': 'Encaminhamento para Médico',
   '8007': 'Encaminhamento para Médico - Plantão Médico',
   '8008': 'Encaminhamento para Médico - Médico Específico',
   '8009': 'Encaminhamento Agendamento',
   '8010': 'Ação do Encaminhamento para Médico',
   '8011': 'Set Link Agora Token',
   '8012': 'Get Link Agora Token',
   '8013': 'Get Link Agora Token - FirestoreId',
   '8014': 'Apagar Link Agora Token',
   '8015': 'Limpando dados do Paciente',
   // CCAPI - assignPatientToAvailableProfessional
   '8016': 'Remoção do Profissional ARM com paciente',
   '8017': 'Remoção do Profissional Médico com paciente',
   '8018': 'Fila de Requisição: Sem Profissional ARM livre',
   '8019': 'Fila de Requisição: Profissional ARM livre',

   '8100': '',

   '8200': '',
   // CCAPI - CallingCircle
   '8301': 'Mover Paciente de um Profissional para Outro',
   '8302': 'Enfileirando Paciente na Fila Médica',

   '8400': '',

   '8500': '',

   '8600': '',

   '8700': '',

   '8800': '',

   '8900': ''
}
