import packageInfo from '../../package.json'

export default {
  data() {
    return {
      client: process.env.VUE_APP_THEME.toLowerCase()
    }
  },

  computed: {
    appVersion() {
      return packageInfo.version;
    },
    appSystemFrom () {
      if (this.$route.query?.from) {
        return this.$route.query.from.toLocaleUpperCase()
      }

      return String(process.env.VUE_APP_SYSTEM_FROM_PA).toLocaleUpperCase()
    },
    appSystemTo () {
      return String(process.env.VUE_APP_SYSTEM_TO_PA).toLocaleUpperCase()
    },
    appLogo() {
      switch (this.appSystemFrom) {
        case 'AXIA':
          return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/icone-axia_1.png?alt=media&token=b63e6c51-b38e-4519-950a-ec45b9ebaef9'
        case 'HOMEANGELS':
          return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/home-angels.png?alt=media&token=071a4ff0-7d57-4f0d-b8b1-0d10d063f76c'
        
        default:
          return process.env.VUE_APP_IMG_LOGO
      }
    },
    appLogoChat() {
      switch (this.appSystemFrom) {
        case 'AXIA':
          return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/icone-axia_1.png?alt=media&token=b63e6c51-b38e-4519-950a-ec45b9ebaef9'
        case 'HOMEANGELS':
          return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/home-angels.png?alt=media&token=071a4ff0-7d57-4f0d-b8b1-0d10d063f76c'
        case 'MAXPLANET':
          return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/maxplanet.svg?alt=media&token=e943bfd1-6e86-4438-9f62-e21d7afc3c81'
  
        default:
          return process.env.VUE_APP_IMG_LOGO
      }
    },
    appLogoWhite() {
      if (this.client === "axia") {
        return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/icone-axia_1.png?alt=media&token=b63e6c51-b38e-4519-950a-ec45b9ebaef9'
      }

      return process.env.VUE_APP_IMG_LOGO_BRANCA
    },
    appDeeplink() {
      if (this.$route.query.deeplink) {
        return this.$route.query.deeplink.toLocaleLowerCase()
      }

      return this.appSystemFrom !== this.appSystemTo ? process.env.VUE_APP_AXIA_DEEPLINK : process.env.VUE_APP_IOS_DEEPLINK
    },
    giftCardSvg () {
      if (this.client === "maxplanet") {
        return require('@/assets/gift_card_3.svg')
      }
      if (this.client === "medgold") {
        return require('@/assets/gift_card_medgold.svg')
      }
  
      return require('@/assets/gift_card_2.svg')
    },
    svgColor () {
      if (this.client === "maxplanet") {
        return "#175E9E"
      }
  
      return "#730707"
    },
    svgSupportColor () {
      if (this.client === "maxplanet") {
        return "#159597"
      } else if (this.client === "medgold") {
        return '#F9A328'
      }
  
      return "#730707"
    },
    privacyForm() {
      if (this.client === "maxplanet") {
        return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/priv-maxplanet.pdf?alt=media&token=cc81fa1b-4802-4af7-9fb4-d90d51e4ef9b#toolbar=0'
      }

      if (this.client === 'medgold') {
        return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/Politica_de_Privacidade_MEDGOLD_23_07_24.pdf?alt=media&token=19028df6-4039-4ee9-a413-72d6d9d0fe42'
      }

      return null
    },
    consentForm() {
      if (this.client === "maxplanet") {
        return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/terms-maxplanet.pdf?alt=media&token=f4d92cac-9af5-40fe-95b4-5a98ff1115c6#toolbar=0'
      }

      if (this.client === 'medgold') {
        return 'https://firebasestorage.googleapis.com/v0/b/medicar-production.appspot.com/o/Termos%20de%20uso_MEDGOLD_23_07_24.pdf?alt=media&token=dbb263c3-3bc6-48ed-bf78-d0698c2686b3'
      }

      return null
    },
    termComponentName() { // return the component name
      if(["maxplanet", "medgold"].includes(this.client)) {
        return "ConsentTermIframe"
      } else if (this.client === "axia") {
        return "TermAxia"
      } else {
        return "TermMedicar";
      }
    },
  },  
  watch: {
    '$route.query': {
      immediate: true,
      handler(value) {
        if (value?.from) {
          document.getElementById("root").className = this.appSystemFrom.toLocaleLowerCase()

          if (value.from.toUpperCase() === 'AXIA') {
            const favicon = document.getElementById("favicon");
            favicon.setAttribute("href", `${process.env.VUE_APP_AXIA_PACIENTE}/favicon.png`);
          }
        }
      }
    }
  }
};
